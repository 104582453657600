import React from 'react';
import type { TabsAlignment } from 'wix-ui-tpa/cssVars';
import { Tabs, TabsSkin, Dropdown, TabsVariant, DropdownTheme } from 'wix-ui-tpa/cssVars';
import { useNavigationLogic } from './Navigation.logic';
import type { PopulatedMenu } from 'root/apiTypes';
import type { NavigateToFn } from 'root/clientTypes';
import { MenuListNavigation, NavigationDropdownStyle } from 'root/consts';
import { classes } from './Navigation.st.css';

export const Navigation = ({
  activeItem,
  setActiveItem,
  menus,
  navigateTo,
}: {
  activeItem: number;
  setActiveItem: (index: number) => void;
  menus: PopulatedMenu[];
  navigateTo: NavigateToFn;
}) => {
  const {
    navigationLayout,
    dropdownOptions,
    navigationAlignmentString,
    tabOptions,
    onClickNavigationDropdownOption,
    onClickTab,
    navigationDropdownStyle,
  } = useNavigationLogic({
    menus,
    setActiveItem,
    navigateTo,
  });

  return (
    <div className={classes.container}>
      {navigationLayout === MenuListNavigation.Tabs ? (
        <Tabs
          activeTabIndex={activeItem}
          alignment={navigationAlignmentString as TabsAlignment}
          className={classes.tabs}
          items={tabOptions}
          skin={TabsSkin.fitUnderline}
          variant={TabsVariant.fit}
          onTabClick={onClickTab}
        />
      ) : (
        <div style={{ display: 'flex', justifyContent: navigationAlignmentString }}>
          <div style={{ minWidth: '387px' }}>
            <Dropdown
              className={classes.dropdown}
              initialSelectedId={activeItem.toString()}
              options={dropdownOptions}
              theme={navigationDropdownStyle === NavigationDropdownStyle.Box ? DropdownTheme.Box : DropdownTheme.Line}
              onChange={onClickNavigationDropdownOption}
            />
          </div>
        </div>
      )}
    </div>
  );
};
