import { StyleParamType, createStylesParams } from '@wix/tpa-settings';
import type { StylesParamsType } from 'root/components/Menus/stylesParams';
import { Alignment, ImageRatio } from 'root/consts';
import { Preset } from 'root/utils/presets';
import { DEFAULT_LARGE_SPACING, DEFAULT_SMALL_SPACING } from 'root/components/Menus/Settings/Fields/consts';

export type IGridLayoutStylesParams = {
  gridNumOfColumns: StyleParamType.Number;
  gridMenuHorizontalPadding: StyleParamType.Number;
  gridMenuVerticalPadding: StyleParamType.Number;
  gridMenuAlignment: StyleParamType.Number;
  gridSectionsGap: StyleParamType.Number;
  gridSectionPadding: StyleParamType.Number;
  gridSectionAlignment: StyleParamType.Number;
  gridItemsRowGap: StyleParamType.Number;
  gridItemsColumnGap: StyleParamType.Number;
  gridItemPadding: StyleParamType.Number;
  gridItemAlignment: StyleParamType.Number;
  gridItemDetailsPadding: StyleParamType.Number;
  gridMenuNavigationLayout: StyleParamType.Number;
  gridMenuNavigationAlignment: StyleParamType.Number;
  gridItemImageBottomPadding: StyleParamType.Number;
  gridItemImageRatio: StyleParamType.Number;
};

export const gridLayoutStylesParams = createStylesParams<IGridLayoutStylesParams>({
  gridNumOfColumns: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 1 : 3),
  },
  gridMenuHorizontalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? DEFAULT_SMALL_SPACING : DEFAULT_LARGE_SPACING),
  },
  gridMenuVerticalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? DEFAULT_SMALL_SPACING : DEFAULT_LARGE_SPACING),
  },
  gridMenuAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isRTL }) => (isRTL ? Alignment.Right : Alignment.Left),
  },
  gridSectionsGap: {
    type: StyleParamType.Number,
    getDefaultValue: () => 80,
  },
  gridSectionPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  gridSectionAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isRTL }) => (isRTL ? Alignment.Right : Alignment.Left),
  },
  gridItemsRowGap: {
    type: StyleParamType.Number,
    getDefaultValue: () => 40,
  },
  gridItemsColumnGap: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  gridItemPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  gridItemAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isRTL }) => (isRTL ? Alignment.Right : Alignment.Left),
  },
  gridItemDetailsPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  gridMenuNavigationLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  gridMenuNavigationAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isRTL }) => (isRTL ? Alignment.Right : Alignment.Left),
  },
  gridItemImageBottomPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  gridItemImageRatio: {
    type: StyleParamType.Number,
    getDefaultValue: () => ImageRatio['1:1'],
  },
});

export type IColumnsLayoutStylesParams = {
  columnsNumOfColumns: StyleParamType.Number;
  columnsMenuHorizontalPadding: StyleParamType.Number;
  columnsMenuVerticalPadding: StyleParamType.Number;
  columnsSectionsGap: StyleParamType.Number;
  columnsSectionPadding: StyleParamType.Number;
  columnsItemsGap: StyleParamType.Number;
  columnsColumnsGap: StyleParamType.Number;
  columnsItemPadding: StyleParamType.Number;
  columnsMenuAlignment: StyleParamType.Number;
  columnsSectionAlignment: StyleParamType.Number;
  columnsItemImageRatio: StyleParamType.Number;
  columnsMenuNavigationLayout: StyleParamType.Number;
  columnsMenuNavigationAlignment: StyleParamType.Number;
};

export const columnsLayoutStylesParams = createStylesParams<IColumnsLayoutStylesParams>({
  columnsNumOfColumns: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 1 : 2),
  },
  columnsMenuHorizontalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? DEFAULT_SMALL_SPACING : DEFAULT_LARGE_SPACING),
  },
  columnsMenuVerticalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? DEFAULT_SMALL_SPACING : DEFAULT_LARGE_SPACING),
  },
  columnsSectionsGap: {
    type: StyleParamType.Number,
    getDefaultValue: () => 40,
  },
  columnsSectionPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  columnsItemsGap: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  columnsColumnsGap: {
    type: StyleParamType.Number,
    getDefaultValue: () => 80,
  },
  columnsItemPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  columnsMenuAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isRTL }) => (isRTL ? Alignment.Right : Alignment.Left),
  },
  columnsSectionAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isRTL }) => (isRTL ? Alignment.Right : Alignment.Left),
  },
  columnsItemImageRatio: {
    type: StyleParamType.Number,
    getDefaultValue: () => ImageRatio['1:1'],
  },
  columnsMenuNavigationLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  columnsMenuNavigationAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isRTL }) => (isRTL ? Alignment.Right : Alignment.Left),
  },
});

export type ICenterLayoutStylesParams = {
  centerMenuHorizontalPadding: StyleParamType.Number;
  centerMenuVerticalPadding: StyleParamType.Number;
  centerSectionsGap: StyleParamType.Number;
  centerSectionPadding: StyleParamType.Number;
  centerItemsRowGap: StyleParamType.Number;
  centerItemPadding: StyleParamType.Number;
  centerMenuNavigationLayout: StyleParamType.Number;
};

export const centerLayoutStylesParams = createStylesParams<ICenterLayoutStylesParams>({
  centerMenuHorizontalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 20 : 40),
  },
  centerMenuVerticalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 20 : 40),
  },
  centerSectionsGap: {
    type: StyleParamType.Number,
    getDefaultValue: () => 60,
  },
  centerSectionPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  centerItemsRowGap: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  centerItemPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  centerMenuNavigationLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
});

export type IGeneralLayoutStylesParams = {
  preset: StyleParamType.Number;
};

export const generalLayoutStylesParams = createStylesParams<IGeneralLayoutStylesParams>({
  preset: {
    type: StyleParamType.Number,
    getDefaultValue: () => Preset.Grid,
  },
});

export type ILayoutStylesParams = IGeneralLayoutStylesParams &
  IGridLayoutStylesParams &
  IColumnsLayoutStylesParams &
  ICenterLayoutStylesParams;

export const LayoutStylesParams: StylesParamsType<ILayoutStylesParams> = {
  ...generalLayoutStylesParams,
  ...gridLayoutStylesParams,
  ...columnsLayoutStylesParams,
  ...centerLayoutStylesParams,
};
