import { StyleParamType, wixColorParam, createStylesParams, wixFontParam } from '@wix/tpa-settings';
import type { StylesParamsType } from 'root/components/Menus/stylesParams';
import { NavigationDropdownStyle } from 'root/consts';

type IMenusTabsNavigationStyleParams = {
  navigationTabsTextColor: StyleParamType.Color;
  navigationTabsTextFont: StyleParamType.Font;
  navigationTabsSelectedIndicatorColor: StyleParamType.Color;
};

export const navigationTabsStyleParams = createStylesParams<IMenusTabsNavigationStyleParams>({
  navigationTabsTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('wst-color-text-primary'),
  },
  navigationTabsTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  navigationTabsSelectedIndicatorColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('wst-color-action'),
  },
});

type IMenusNavigationDropdownStyleParams = {
  navigationDropdownStyle: StyleParamType.Number;
  navigationDropdownTextColor: StyleParamType.Color;
  navigationDropdownTextFont: StyleParamType.Font;
  navigationDropdownBackgroundColor: StyleParamType.Color;
  navigationDropdownBorderColor: StyleParamType.Color;
  navigationDropdownBorderWidth: StyleParamType.Number;
  navigationDropdownBorderRadius: StyleParamType.Number;
};

export const navigationDropdownStyleParams = createStylesParams<IMenusNavigationDropdownStyleParams>({
  navigationDropdownStyle: {
    type: StyleParamType.Number,
    getDefaultValue: () => NavigationDropdownStyle.Box,
  },
  navigationDropdownTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('wst-color-text-primary'),
  },
  navigationDropdownTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  navigationDropdownBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('wst-color-fill-background-primary'),
  },
  navigationDropdownBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('wst-color-line'),
  },
  navigationDropdownBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  navigationDropdownBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
});

type IMenusNavigationStylesParams = IMenusTabsNavigationStyleParams & IMenusNavigationDropdownStyleParams;

export const menusNavigationStylesParams = {
  ...navigationTabsStyleParams,
  ...navigationDropdownStyleParams,
};

type IMenusStylesParams = {
  menuBackgroundColor: StyleParamType.Color;
  // menuBackgroundShadow: StyleParamType.Color;
  menuBorderColorLeft: StyleParamType.Color;
  menuBorderColorTop: StyleParamType.Color;
  menuBorderColorRight: StyleParamType.Color;
  menuBorderColorBottom: StyleParamType.Color;
  menuBorderWidthLeft: StyleParamType.Number;
  menuBorderWidthTop: StyleParamType.Number;
  menuBorderWidthRight: StyleParamType.Number;
  menuBorderWidthBottom: StyleParamType.Number;
  menuBorderRadiusTopRight: StyleParamType.Number;
  menuBorderRadiusTopLeft: StyleParamType.Number;
  menuBorderRadiusBottomRight: StyleParamType.Number;
  menuBorderRadiusBottomLeft: StyleParamType.Number;
  menuTitleTheme: StyleParamType.Font;
  menuTitleColor: StyleParamType.Color;
  menuDescriptionTheme: StyleParamType.Font;
  menuDescriptionColor: StyleParamType.Color;
};

export const menusStylesParams = createStylesParams<IMenusStylesParams>({
  menuBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  // menuBackgroundShadow: {
  //   type: StyleParamType.Color,
  //   getDefaultValue: wixColorParam('color-1'),
  // },
  menuBorderColorLeft: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  menuBorderColorTop: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  menuBorderColorRight: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  menuBorderColorBottom: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  menuBorderWidthLeft: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  menuBorderWidthTop: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  menuBorderWidthRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  menuBorderWidthBottom: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  menuBorderRadiusTopRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  menuBorderRadiusTopLeft: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  menuBorderRadiusBottomRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  menuBorderRadiusBottomLeft: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  menuTitleTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 32,
    }),
  },
  menuTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('wst-color-title'),
  },
  menuDescriptionTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  menuDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('wst-color-text-primary'),
  },
});

type ISectionsStylesParams = {
  sectionBackgroundColor: StyleParamType.Color;
  // sectionBackgroundShadow: StyleParamType.Color;
  sectionBorderColorLeft: StyleParamType.Color;
  sectionBorderColorRight: StyleParamType.Color;
  sectionBorderColorTop: StyleParamType.Color;
  sectionBorderColorBottom: StyleParamType.Color;
  sectionBorderWidthLeft: StyleParamType.Number;
  sectionBorderWidthRight: StyleParamType.Number;
  sectionBorderWidthTop: StyleParamType.Number;
  sectionBorderWidthBottom: StyleParamType.Number;
  sectionBorderRadiusTopRight: StyleParamType.Number;
  sectionBorderRadiusTopLeft: StyleParamType.Number;
  sectionBorderRadiusBottomRight: StyleParamType.Number;
  sectionBorderRadiusBottomLeft: StyleParamType.Number;
  sectionTitleTheme: StyleParamType.Font;
  sectionTitleColor: StyleParamType.Color;
  sectionDescriptionTheme: StyleParamType.Font;
  sectionDescriptionColor: StyleParamType.Color;
  sectionTopDividerWidth: StyleParamType.Number;
  sectionTopDividerColor: StyleParamType.Color;
  sectionBottomDividerWidth: StyleParamType.Number;
  sectionBottomDividerColor: StyleParamType.Color;
};

export const sectionsStylesParams = createStylesParams<ISectionsStylesParams>({
  sectionBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  // sectionBackgroundShadow: {
  //   type: StyleParamType.Color,
  //   getDefaultValue: wixColorParam('color-1'),
  // },
  sectionBorderColorLeft: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sectionBorderColorRight: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sectionBorderColorTop: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sectionBorderColorBottom: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sectionBorderWidthLeft: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sectionBorderWidthRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sectionBorderWidthTop: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sectionBorderWidthBottom: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sectionBorderRadiusTopRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sectionBorderRadiusTopLeft: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sectionBorderRadiusBottomRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sectionBorderRadiusBottomLeft: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sectionTitleTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 24,
    }),
  },
  sectionTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('wst-color-title'),
  },
  sectionDescriptionTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  sectionDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('wst-color-text-primary'),
  },
  sectionTopDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sectionTopDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sectionBottomDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sectionBottomDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
});

type IItemsStylesParams = {
  itemBackgroundColor: StyleParamType.Color;
  // itemBackgroundShadow: StyleParamType.Color;
  itemBorderColorLeft: StyleParamType.Color;
  itemBorderColorRight: StyleParamType.Color;
  itemBorderColorTop: StyleParamType.Color;
  itemBorderColorBottom: StyleParamType.Color;
  itemBorderWidthLeft: StyleParamType.Number;
  itemBorderWidthRight: StyleParamType.Number;
  itemBorderWidthTop: StyleParamType.Number;
  itemBorderWidthBottom: StyleParamType.Number;
  itemBorderRadiusTopRight: StyleParamType.Number;
  itemBorderRadiusTopLeft: StyleParamType.Number;
  itemBorderRadiusBottomRight: StyleParamType.Number;
  itemBorderRadiusBottomLeft: StyleParamType.Number;
  itemTitleTheme: StyleParamType.Font;
  itemTitleColor: StyleParamType.Color;
  itemDescriptionTheme: StyleParamType.Font;
  itemDescriptionColor: StyleParamType.Color;
  itemPriceTheme: StyleParamType.Font;
  itemPriceColor: StyleParamType.Color;
  itemImageRadiusTopRight: StyleParamType.Number;
  itemImageRadiusTopLeft: StyleParamType.Number;
  itemImageRadiusBottomRight: StyleParamType.Number;
  itemImageRadiusBottomLeft: StyleParamType.Number;
  itemDividerColor: StyleParamType.Color;
  itemDividerWidth: StyleParamType.Number;
};

export const itemsStylesParams = createStylesParams<IItemsStylesParams>({
  itemBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  // itemBackgroundShadow: {
  //   type: StyleParamType.Color,
  //   getDefaultValue: wixColorParam('color-1'),
  // },
  itemBorderColorLeft: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  itemBorderColorRight: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  itemBorderColorTop: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  itemBorderColorBottom: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  itemBorderWidthLeft: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemBorderWidthRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemBorderWidthTop: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemBorderWidthBottom: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemBorderRadiusTopRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemBorderRadiusTopLeft: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemBorderRadiusBottomRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemBorderRadiusBottomLeft: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemTitleTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 16,
    }),
  },
  itemTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('wst-color-title'),
  },
  itemDescriptionTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  itemDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('wst-color-text-primary'),
  },
  itemPriceTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  itemPriceColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  itemImageRadiusTopRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemImageRadiusTopLeft: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemImageRadiusBottomRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemImageRadiusBottomLeft: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  itemDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  itemDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
});

type IVariantsStylesParams = {
  variantTitleTheme: StyleParamType.Font;
  variantTitleColor: StyleParamType.Color;
  variantPriceTheme: StyleParamType.Font;
  variantPriceColor: StyleParamType.Color;
};

export const variantsStylesParams = createStylesParams<IVariantsStylesParams>({
  variantTitleTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  variantTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  variantPriceTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  variantPriceColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
});

type ILabelsStylesParams = {
  labelBackgroundColor: StyleParamType.Color;
  labelBorderColor: StyleParamType.Color;
  labelBorderWidth: StyleParamType.Number;
  labelBorderRadiusTopRight: StyleParamType.Number;
  labelBorderRadiusTopLeft: StyleParamType.Number;
  labelBorderRadiusBottomRight: StyleParamType.Number;
  labelBorderRadiusBottomLeft: StyleParamType.Number;
  labelTitleTheme: StyleParamType.Font;
  labelTitleColor: StyleParamType.Color;
  labelIconColor: StyleParamType.Color;
};

export const labelsStylesParams = createStylesParams<ILabelsStylesParams>({
  labelBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  labelBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  labelBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  labelBorderRadiusTopRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  labelBorderRadiusTopLeft: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  labelBorderRadiusBottomRight: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  labelBorderRadiusBottomLeft: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  labelTitleTheme: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  labelTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  labelIconColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
});

export type IDesignStylesParams = IMenusNavigationStylesParams &
  IMenusStylesParams &
  ISectionsStylesParams &
  IItemsStylesParams &
  IVariantsStylesParams &
  ILabelsStylesParams;

export const DesignStylesParams: StylesParamsType<IDesignStylesParams> = {
  ...menusNavigationStylesParams,
  ...menusStylesParams,
  ...sectionsStylesParams,
  ...itemsStylesParams,
  ...variantsStylesParams,
  ...labelsStylesParams,
};
