import { SettingsParamType, createSettingsParams } from '@wix/tpa-settings';

export type IDisplaySettingsParams = {
  checkImagePlaceholderMenus?: SettingsParamType.Object;
  checkPricingOptionsMenus?: SettingsParamType.Object;
  imagePlaceholder: SettingsParamType.String;
};

export type MenuOrder = { id: string; checked: boolean };

export const DisplaySettingsParams = createSettingsParams<IDisplaySettingsParams>({
  checkImagePlaceholderMenus: {
    type: SettingsParamType.Object,
    getDefaultValue: () => undefined,
  },
  checkPricingOptionsMenus: {
    type: SettingsParamType.Object,
    getDefaultValue: () => undefined,
  },
  imagePlaceholder: {
    type: SettingsParamType.String,
    getDefaultValue: () => '',
  },
});
