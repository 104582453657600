import React from 'react';

export const DefaultImagePlaceholder = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      fill="none"
      preserveAspectRatio="xMidYMid slice"
      viewBox="0 0 1280 720"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3166_60480)">
        <rect fill="#DFDFDF" height="720" width="1280" />
        <g clip-path="url(#clip1_3166_60480)">
          <path
            d="M701.79 294H578.21C575.89 294 574 295.89 574 298.21V421.78C574 424.11 575.89 425.99 578.21 425.99H701.78C704.11 425.99 705.99 424.1 705.99 421.78V298.21C705.99 295.88 704.1 294 701.78 294H701.79Z"
            stroke="black"
            stroke-linejoin="round"
            stroke-opacity="0.7"
            stroke-width="2"
          />
          <path
            d="M665 411H668C670.21 411 672 409.21 672 407V377H661V407C661 409.21 662.79 411 665 411Z"
            stroke="black"
            stroke-linejoin="round"
            stroke-opacity="0.7"
            stroke-width="2"
          />
          <path
            d="M652 377H672V310C657.78 314.15 648 327.19 648 342V373C648 375.21 649.79 377 652 377Z"
            stroke="black"
            stroke-linejoin="round"
            stroke-opacity="0.7"
            stroke-width="2"
          />
          <path
            d="M608 309V338"
            stroke="black"
            stroke-linecap="square"
            stroke-linejoin="round"
            stroke-opacity="0.7"
            stroke-width="2"
          />
          <path
            d="M615 309V333"
            stroke="black"
            stroke-linecap="square"
            stroke-linejoin="round"
            stroke-opacity="0.7"
            stroke-width="2"
          />
          <path
            d="M622 309V333"
            stroke="black"
            stroke-linecap="square"
            stroke-linejoin="round"
            stroke-opacity="0.7"
            stroke-width="2"
          />
          <path
            d="M629 309V338"
            stroke="black"
            stroke-linecap="square"
            stroke-linejoin="round"
            stroke-opacity="0.7"
            stroke-width="2"
          />
          <path
            d="M608 333V342.13C608 345.57 609.65 348.61 612.19 350.53C613.9 351.82 615 353.77 615 355.92V406.86C615 408.48 616.27 410.2 617.86 410.48C620.08 410.87 622 409.18 622 407.04V355.91C622 353.76 623.1 351.81 624.81 350.52C627.35 348.6 629 345.55 629 342.12V332.99H608V333Z"
            stroke="black"
            stroke-linejoin="round"
            stroke-opacity="0.7"
            stroke-width="2"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3166_60480">
          <rect fill="white" height="720" width="1280" />
        </clipPath>
        <clipPath id="clip1_3166_60480">
          <rect fill="white" height="134" transform="translate(573 293)" width="134" />
        </clipPath>
      </defs>
    </svg>
  );
};
