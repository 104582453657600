import { useStyles } from '@wix/tpa-settings/react';
import { stylesParams } from 'root/components/Menus/stylesParams';
import type { Preset } from 'root/utils/presets';

export const usePriceVariantsLogic = () => {
  const styles = useStyles();
  const preset = styles.get(stylesParams.preset) as Preset;

  return { preset };
};
