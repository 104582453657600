import React from 'react';
import type { ItemVariant } from 'root/apiTypes';
import { Variant } from 'root/components/Menus/Variant/Variant';
import { classes } from './PriceVariants.st.css';
import { dataHooks } from 'root/dataHooks';
import { usePriceVariantsLogic } from './PriceVariants.logic';
import { Preset } from 'root/utils/presets';

export const PriceVariants = ({ variants }: { variants: ItemVariant[] }) => {
  const { preset } = usePriceVariantsLogic();

  return (
    <div className={classes.priceVariants} data-hook={dataHooks.item.priceVariants}>
      {variants.map((variant, index) => (
        <Variant
          key={variant.id}
          showDivider={preset === Preset.Center && index !== variants.length - 1}
          variant={variant}
        />
      ))}
    </div>
  );
};
