import React from 'react';
import type { ShowcasePopulatedItem } from 'root/apiTypes';
import { st, classes } from './Item.st.css';
import { useItemLogic } from './Item.logic';
import { Labels } from 'root/components/Menus/Labels/Labels';
import { PriceVariants } from 'root/components/Menus/PriceVariants/PriceVariants';
import { Text, Divider, WowImage, ImageResizeOptions } from 'wix-ui-tpa/cssVars';
import { dataHooks } from 'root/dataHooks';
import { classes as global } from 'root/components/Menus/Global.st.css';
import { DefaultImagePlaceholder } from 'root/assets/icons/DefaultImagePlaceholder';

export type ItemProps = {
  item: ShowcasePopulatedItem;
};

export const Item = ({ item }: ItemProps) => {
  const {
    hasPriceVariants,
    priceContent,
    showItemDescription,
    shouldShowPrice,
    showItemImage,
    textAlignCss,
    shouldShowLabels,
    shouldShowItemDividers,
    shouldShowDefaultPlaceholder,
    shouldShowImagePlaceholder,
    imagePlaceholder,
  } = useItemLogic({ item });

  return (
    <div className={classes.root}>
      <div
        className={st(classes.itemContainer, showItemImage ? undefined : classes.noImage)}
        data-hook={dataHooks.item.container}
        style={textAlignCss}
      >
        {shouldShowItemDividers && <Divider className={classes.divider} data-hook={dataHooks.item.divider} />}
        <Text className={st(global.fullWidth, classes.itemName)} data-hook={dataHooks.item.name}>
          {item.name}
        </Text>
        {showItemDescription && (
          <Text className={st(global.fullWidth, classes.itemDescription)} data-hook={dataHooks.item.description}>
            {item.description}
          </Text>
        )}
        {hasPriceVariants ? (
          <PriceVariants variants={item.priceVariants?.variants ?? []} />
        ) : (
          shouldShowPrice && (
            <Text className={st(global.fullWidth, classes.itemPrice)} data-hook={dataHooks.item.price}>
              {priceContent}
            </Text>
          )
        )}
        {shouldShowLabels && <Labels data-hook={dataHooks.item.labels} labels={item.labels!} />}
        {item.image ? (
          showItemImage && (
            <WowImage
              shouldUseLQIP
              alt={item.image?.altText ?? ''}
              className={classes.itemImage}
              data-hook={dataHooks.item.image}
              resize={ImageResizeOptions.cover}
              sourceHeight={item.image?.height}
              sourceWidth={item.image?.width}
              src={item.image?.url ?? ''}
            />
          )
        ) : shouldShowImagePlaceholder ? (
          shouldShowDefaultPlaceholder ? (
            <DefaultImagePlaceholder className={classes.itemDefaultImagePlaceholder} />
          ) : (
            <WowImage
              shouldUseLQIP
              className={classes.itemImage}
              data-hook={dataHooks.item.image}
              resize={ImageResizeOptions.cover}
              src={(imagePlaceholder as string) ?? ''}
            />
          )
        ) : undefined}
        {shouldShowItemDividers && <Divider className={classes.divider} data-hook={dataHooks.item.divider} />}
      </div>
    </div>
  );
};
