import type { ShowcasePopulationLevel } from 'root/apiTypes';
import type { PageData, Range } from './clientTypes';

export const MAX_NUMBER_OF_ENTITIES_FROM_SERVER = 500;
export const DEFAULT_APP_WIDTH = 980;

export const SHOWCASE_POPULATION_LEVEL: ShowcasePopulationLevel = {
  labels: true,
  modifierGroups: false,
  modifiers: false,
  priceVariants: true,
};

export const MENU_WIDGET_NAME = 'Menu';
export const PAGE_NAME = 'menus';

export enum MenuGFPPButtons {
  Settings = 'menu settings button',
  Manage = 'manage menus button',
}
export enum MenuPanelName {
  Settings = 'menu settings',
  Manage = 'manage menus',
}

export const APP_DEF_ID = 'a63b2da7-5c57-4beb-9341-51ce59c50972';

export const WIDGET_IDS = {
  menus: 'ac6175c0-249c-42f3-81e5-c8facc4bd0bf',
};

export const PAGE_DATA: PageData = {
  pageId: 'Menus',
  title: 'app.page.title',
  widgetId: WIDGET_IDS.menus,
  pageUriSEO: 'menus-ooi',
};
export const BADGE_ICON_SIZE = 16;

export enum Alignment {
  Left,
  Center,
  Right,
}

export enum ImageRatio {
  '16:9' = 16 / 9,
  '4:3' = 4 / 3,
  '1:1' = 1,
  '3:4' = 3 / 4,
  '9:16' = 9 / 16,
}

export const BORDER_INPUT_WIDTH = 170;

export enum MenuListNavigation {
  Tabs = 0,
  Dropdown = 1,
}

export enum FieldName {
  MaxItems = 'maxItemsPerRow',
  ColumnsNumber = 'numberOfColumns',
  ColumnsGap = 'columnsGap',
  ColumnsSectionGap = 'columnsSectionGap',
  ColumnsItemsGap = 'columnsItemsGap',
  ColumnsItemPadding = 'columnsItemPadding',
  SectionsGap = 'sectionsGap',
  ItemsGap = 'itemsGap',
  SectionPadding = 'sectionPadding',
  ItemPadding = 'itemPadding',
  ItemDetailsPadding = 'itemDetailsPadding',
  Default = 'default',
  NavigationLayout = 'navigationLayout',
  NavigationAlignment = 'navigationAlignment',
  MenuAlignment = 'menuAlignment',
  SectionAlignment = 'sectionAlignment',
  ItemAlignment = 'itemAlignment',
  menuBackgroundHorizontalPadding = 'menuBackgroundHorizontalPadding',
  menuBackgroundVerticalPadding = 'menuBackgroundVerticalPadding',
  ItemImageBottomPadding = 'itemImageBottomPadding',
}
export const defaultFontSizeRange: Range = { min: 12, max: 100 };

export enum NavigationDropdownStyle {
  Underline,
  Box,
}
